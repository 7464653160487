
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { numberFormat } from "../../../../functions";
import moment from "moment";

export function StatsMonthlyOffer( { className, offer } ) {
    let series = [];
    let categories = [];

    if (offer) {
        offer.map( (data) => {
            series.push( parseFloat( data.TeamAmount ) );
            categories.push( data.TeamName );

        } );
    }

    var titleSerie = "Offer Ranking";
    var idGraph = "kt_stats_offer";
    var colorsGraph = '#020d94';   //'#002060'; // ['#002060', '#F5B013'];

//console.log( "CARGANDO GRAFICA NUM SERIE -------->>>>>>>>>>>", series, categories );
    useEffect( () => {
        const element = document.getElementById( idGraph );
        if (!element) {
            return;

        }

        const options = {
            series: [
                {
                    name: titleSerie,
                    data: series,
                },
 
            ],
            chart: {
                height: 350,
                type: 'bar',
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            colors: colorsGraph, /* ['#008FFB', '#00E396', '#CED4DC' ], //['#77B6EA', '#545454'], */
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return numberFormat( val );
                },
            },
            stroke: {
                curve: 'smooth'
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories: categories,

            },
            yaxis: {
                labels: {
                    style: {
                        //colors: ['#FF5733', '#33FF57', '#3357FF', '#F0A500', '#8D33FF'], // Colores personalizados para cada etiqueta
                        fontSize: '12px', // Tamaño de fuente
                        fontWeight: 'bold', // Peso de la fuente
                        fontFamily: 'Arial, sans-serif' // Familia de fuente
                    }
                }
            },
            fill: {
                type: 'solid', //'gradient',
                opacity: 1,
                /* colors: ['#FF5733', '#33FF57', '#3357FF', '#F0A500', '#8D33FF'],
                colors: ( value, seriesIndex, w ) => { console.log( "COLORS ---->>>>", value );
                    if (value < 40) return '#FF5733';
                    else if (value < 50) return '#33FF57';
                    else return '#3357FF';
                } */
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -25,
                offsetX: -5
            }
        };

        try {
            if (offer) {
                const chart = new ApexCharts( element, options );
                chart.render();
                return function cleanUp() {
                    chart.destroy();
                };
            }
        } catch {}
    }, [offer] );

    return (
        <div className={`card card-custom ${className}`}>
            <div className="card-body d-flex flex-column p-0">
                <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                    <div className="d-flex flex-row justify-content-between mr-2" style={{width: "100%"}}>
                        <span className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5">
                            {titleSerie}
                        </span>
                    </div>
                </div>
                <div className="flex-grow-1">
                    { offer ? (
                        <div id={idGraph} className="card-rounded-bottom" style={{ height: "230px" }}> </div>
                    ) : (
                        <></>
                    ) }
                </div>
            </div>
        </div>
    );
}