import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import api from "../../../api";
import { useSelector } from "react-redux";
import { numberFormat } from "../../../functions";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useSubheader } from "../../layout";
import { format, parseISO } from "date-fns";
import { StatsMonthlyOffer } from "../widgets";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
/* const checkedIcon = <CheckBoxIcon fontSize="small" />; */

export default function MonthlyOffer( props ) {
  const suhbeader = useSubheader();

  const [endDate, setEndDate] = useState( new Date() );
  const [startDate, setStartDate] = useState( moment().subtract( 7, "days" ) );


  const [rowsOffer, setRowsOffer] = useState( [] );
  const { user } = useSelector( (state) => state.auth );
  const [loading, setLoading] = useState( false );

 


  const fetchDataOffer = (start, end) => {
    setLoading( true );
    setStartDate( start );
    setEndDate( end );

    let payload = {
        Year: moment( start ).format( "yyyy" ),
        Month: moment( end ).format( "MM" ),
      }
   
      return api
        .get(`dashboard/graph-monthlyoffer`, {
          params: payload,
        })
        .then(
          (result) => {
            setRowsOffer( result.data.body );

            setLoading( false );
        },
        (error) => {

          setRowsOffer( [] );
          console.log( error );
          setLoading( false );
        }
      );
  }




/*   const handleCallback = async (start, end) => {
    await setStartDate( start );
    await setEndDate( end );
    await fetchDataOffer( start, end );
  }

 */



  useEffect( () => {
    fetchDataOffer( new Date(), new Date());
  }, [] );




  return (
    <>
      <div className="card">
        {/* <div className="card-header border-0 pt-5 bg-light">
          <div className="row align-items-center">
            <div className="col-12 mb-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Oferta del Mes DEV
                </span>
              </h3>
            </div>
          </div>

        </div> */}
        
        <div className="card-body">
          {loading && <span className="ml-3 spinner spinner-white"></span>}
          <div className="row">
            <div className="col-12 mb-5  rounded-xl py-5">
              <div className="row m-0">
                <div className="col-md-12 px-6 py-2 rounded-xl">
                  <h2>Oferta del MES</h2>
                </div>
              </div>
              <div className="row m-0" style={{ backgroundColor: "#F5B013" }}>
                <div className="col-md-12 px-6 py-2" style={{textAlign: "center"}}>
                  <h3 className="text-white ">{`Oferta de ${moment( startDate ).format( "MM/yyyy" )}`}</h3>
                </div>
              </div>


            </div>

          </div>
          


          <div className="row">
            <div className="col-12 mb-5 rounded-xl py-5">
              <div className="col-12 mb-5 bg-light-primary rounded-xl py-5">
                <StatsMonthlyOffer
                  offer={rowsOffer}
                />
              </div>
            </div>
          </div>




        </div>
      </div>
    </>
  );
}
