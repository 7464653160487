/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import api from "../../../../../api";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);

  /* useEffect( () => {
    const fetchData = async () => {
      console.log( "(1) USE EFFECT --->>>>>" );
      await optionAvailables();
    };
    fetchData();
    console.log( "(3-4) ---->>>>>>>" );
    //optionAvailables();
  }, [] );

  // VALIDAR ACCESO A OPCIONES con la nueva funcionalidad desde  LEADer settings .
  const optionAvailables = async () => {
    try {
    const response = await new Promise( (resolve) => 
    api
      .get( 'user/REPORTS/available-menuoptions', { params: { Search: true } } )
      .then(
        (result) => {
          if (result?.status < 400 && result?.data?.error == '') {
            let data = result.data.body;
            user.ReportsOptions = data;
        console.log( "(2) RESULT --->>>", user.ReportsOptions, user.ReportsOptions[ 0 ].Permitted );
  
          }

        },
        (error) => {
          user.ReportsOptions = [];
          console.log(error);
          
        }
      )
    );
    console.log( "(3) RESULTADO ----->>>>>", response );
  } catch (error) {
    console.log( "(2) ERROR ----->>>>>", error );
  }

  };

console.log( "(5) ---->>>>>>>" ); */
  const [opcAdmin, setOpcAdmin] = useState( false );
  const [opcTeamSales1, setOpcTeamSales1] = useState( false );

  //async function availableOption( option ) {
  const availableOption = (option) => {
    //const response = await new Promise( (resolve) => 
    const response = new Promise( (resolve) =>
      api
        .get( 'user/REPORTS/available-menuoptions', { params: { Option: option } } )
        .then(
          (result) => {
            //console.log( "rESULT API (62) ----->>>>>", result );
            return result.data.body;

          },
          (error) => {
            console.log(error);
            return false;
        
          }
        )
    );
  //console.log( "RESPONSE (77)----->>>>>>>", response );
    return response;
  }

  const teamsFelixParra = [ 4443, 4443, 6990, 6728, 7028, 4398, 1631, 6403, 1848, 5685, 2954, 2954, 7114, 6746, 6833, 
                            6830, 7139, 7205, 1820, 7164, 6760, 4508, 7082, 6748, 7158, 7159, 6652, 6954, 7181, 7191, 5535];
  const managerFelixParra = [ 2954, 4443, 4398, 6728, 7285, 7313 ];
  
  /* useEffect( () => {
    fetchRows();
  }, [] ); */

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      <li className="menu-section ">
        <h4 className="menu-text">{`${user.Company && user.Company.Name ? user.Company.Name : ''}`}</h4>
        <i className="menu-icon flaticon-more-v2"></i>
      </li>

      {/* TABLERO ACTIVIDADES */}
      { (user && user.RoleId !== 11) && user?.RoleId !== 15  ? (
          <li
            className={`menu-item ${getMenuItemActive("/activities-dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/activities-dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}
                />
              </span>
              <span className="menu-text">Tablero de actividades</span>
            </NavLink>
          </li>
        ) : (
          <></>
        )}

        {/* TABLERO COMERCIAL */}
        { (user && user.RoleId !== 11) && user?.RoleId !== 15  ? (
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Tablero comercial</span>
            </NavLink>
          </li>
        ) : (
          <></>
        )}

        {/* TABLERO DE VENTAS */}
        { user?.RoleId !== 15  ? (
        <li
          className={`menu-item ${getMenuItemActive(
            "/sales-dashboard",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/sales-dashboard">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")}
              />
            </span>
            <span className="menu-text">Tablero de ventas</span>
          </NavLink>
        </li>
        ) : (
          <></>
        )}



        {/* TABLERO DE VENTAS 2 (POR EQUIPO) 2954, 4443, 4398, 6728, (7285 x 7313) */}
        { user?.RoleId === 1 || managerFelixParra.includes( user.EmployeeId ) ? (
        <li
          className={`menu-item ${getMenuItemActive(
            "/sales-team-dashboard",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/sales-team-dashboard">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")}
              />
            </span>
            <span className="menu-text">Tablero de Ventas por Equipo</span>
          </NavLink>
        </li>
        ) : (
          <></>
        ) }
    


        {/* TABLERO FINANCIERO */}
        { user?.RoleId !== 15  ? (
        <li
          className={`menu-item ${getMenuItemActive(
            "/financial-dashboard",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/financial-dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")} />
            </span>
            <span className="menu-text">Tablero Financiero</span>
          </NavLink>
        </li>
        ) : (
          <></>
        )}


        {/* TABLERO OFERTA MES */}
        { user?.RoleId === 1 || managerFelixParra.includes( user.Employee.manager ) ? (
        <li
          className={`menu-item ${getMenuItemActive(
            "/monthly-offer",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/monthly-offer">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")} />
            </span>
            <span className="menu-text">Oferta del Mes</span>
          </NavLink>
        </li>
        ) : (
          <></>
        )}


        {/* BONOS Y COMISIONES */}
        { user?.RoleId !== 15  ? (
        <li
          className={`menu-item ${getMenuItemActive(
            user.CompanyId == 2 ? "/commissions-report" : "/commissions-bonuses-analytics"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={user.CompanyId == 2 ? "/commissions-report" : "/commissions-bonuses-analytics"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Gift.svg")} />
            </span>
            <span className="menu-text">Bonos y comisiones</span>
          </NavLink>
        </li>
        ) : (
          <></>
        )}

        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Módulos</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* CARRUSEL */}
        { ((user && user.RoleId < 4) || 
              (user && (user.RoleId >= 12 && user.RoleId <= 14)) ||
              (user && (user.RoleId === 5 || user.RoleId === 4) && user.TeamCarousel === 1)) && user?.RoleId !== 15  ? (
          <li
            className={`menu-item ${getMenuItemActive("/carousel", true)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/carousel">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-3d.svg")}
                />
              </span>
              <span className="menu-text">Carrusel</span>
            </NavLink>
          </li>
        ) : (
          <></>
        )}

        {/* PROSPECTOS */}
        {/* { (user.RoleId !== 14 && user?.RoleId !== 15) ? ( */}
        { (user?.RoleId !== 15) ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/prospect",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/prospect">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group.svg"
                  )}
                />
              </span>
              <span className="menu-text">Prospectos</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Prospectos</span>
                  </span>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/prospect/browse-page"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/prospect/browse">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Prospectos</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/prospect/browse-not-following"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/prospect/browse-not-following"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">No interesados</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/prospect/browse-reassigned"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/prospect/browse-reassigned"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Reasignados</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/prospect/browse-client"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/prospect/browse-client">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Clientes</span>
                  </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive(
                      "/prospect/browse-dbreview"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/prospect/browse-dbreview">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Bases de Datos</span>
                    </NavLink>
                </li>

                { (user?.RoleId === 1 || user?.EmployeeId === 2954) ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/prospect/browse-databases"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/prospect/browse-databases">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Bases de Datos - Config</span>
                    </NavLink>
                  </li>
                  ) : (<></>)
                }
                {user?.RoleId === 1 || managerFelixParra.includes( user.Employee.manager ) || managerFelixParra.includes( user.EmployeeId ) ? 
                <li
                    className={`menu-item ${getMenuItemActive(
                      "/prospect/insurance-prices"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/prospect/insurance-prices">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Precios de Seguros</span>
                    </NavLink>
                </li>
                : <></> }



                { /* opcAdmin || opcTeamSales1 ?  */ user?.RoleId === 1 ?
                <li
                    className={`menu-item ${getMenuItemActive(
                      "/prospect/members-offerteams"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/prospect/members-offerteams">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Oferta del Mes Equipos</span>
                    </NavLink>
                </li>
                : <></> }






                {/* {user?.RoleId === 1 /* || user?.Employee?.manager === 2954 || user.Employee.manager === 4443 || user.Employee.manager === 6728 * / ? 
                <li
                    className={`menu-item ${getMenuItemActive(
                      "/prospect/sales-compromise"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/prospect/sales-compromise">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Compromiso de Ventas</span>
                    </NavLink>
                </li>
                : <></> } */}

              </ul>
            </div>
          </li>
        ) : (
          <></>
        )}

        {/* REPORTES */}
        { (user?.EmployeeId === 1831 || user?.EmployeeId === 4006 || 
              (user?.RoleId < 8 && user?.RoleId !== 11) || user?.RoleId >= 12) && user?.RoleId !== 15  ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/report",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/report">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
              </span>
              <span className="menu-text">Reportes</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Reportes</span>
                  </span>
                </li>
                {user?.EmployeeId === 1831 || user?.EmployeeId === 4006 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/rejected"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/rejected">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Conciliaciones</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}

                {(user?.RoleId < 8 && user?.RoleId !== 11) ||
                user?.RoleId >= 13 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/discounts"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/discounts">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Descuentos</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}

                {(user?.RoleId === 1 || user.RoleId >= 13) && user?.EmployeeId !== 6077  ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/discounts-requested"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/report/discounts-requested"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Descuentos solicitados</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}
                {(user?.RoleId < 8 && user?.RoleId !== 11) ||
                user?.RoleId >= 13 && user?.EmployeeId !== 6077 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/activity-types"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/activity-types">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Tipos de actividad</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}
                {(user?.RoleId < 8 && user?.RoleId !== 11) || user?.RoleId >= 13 && user?.EmployeeId !== 6077 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/activities"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/activities">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Actividades</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}
                {(user?.RoleId < 8 && user?.RoleId !== 11) ||
                user?.RoleId >= 13 && user?.EmployeeId !== 6077 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/tipify-tracings"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/tipify-tracings">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Tipificaciónes</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}
                {(user?.RoleId < 8 && user?.RoleId !== 11) ||
                user?.RoleId >= 13 && user?.EmployeeId !== 6077 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/prospection-means"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/report/prospection-means"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Medios de prospección</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}
                {(user?.RoleId < 8 && user?.RoleId !== 11) ||
                user?.RoleId >= 13 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/prospects"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/prospects">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Prospectos</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}
                {(user?.RoleId < 8 && user?.RoleId !== 11) ||
                user?.RoleId >= 13 && user?.EmployeeId !== 6077 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/prospects2"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/prospects2">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Seguimiento a prospectos</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}
                {(user?.RoleId < 8 && user?.RoleId !== 11) ||
                user?.RoleId >= 13  && user?.EmployeeId !== 6077? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/quotations"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/quotations">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Cotizaciones</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}
                {(user?.RoleId < 8 && user?.RoleId !== 11) ||
                user?.RoleId >= 13 && user?.EmployeeId !== 6077 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/weighing"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/weighing">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Ponderación</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}

                { (user?.RoleId === 13 && user?.EmployeeId !== 6077) || teamsFelixParra.includes( user?.EmployeeId ) ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/insurance"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/insurance">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Seguros</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                ) }
                { (user?.RoleId < 3 || user?.RoleId === 13) && user?.EmployeeId !== 6077 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/contract-sheet"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/contract-sheet">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Sábana de Contratos</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                ) }

                {(user?.RoleId < 3 || user?.RoleId === 13) && user?.EmployeeId !== 6077 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/additionals-top"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/additionals-top">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Adicionales Ventas Top</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}

                {(user.RoleId < 6 || user.RoleId === 12 || user.RoleId === 13) && user?.EmployeeId !== 6077 ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/report/marketing"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/report/marketing">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Análisis de Ventas</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}



                {(user.RoleId == 1) ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/contract/beneficiaries-period"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/contract/beneficiaries-period">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Beneficiarios por Periodo</span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}


              </ul>
            </div>
          </li>
        ) : (
          <></>
        )}

        {/* ACTIVIDADES */}
        { ((user && user.RoleId !== 11 && user.RoleId !== 14) || user.RoleId === 13) && user?.RoleId !== 15  ? (
          <li
            className={`menu-item ${getMenuItemActive("/activity/", true)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/activity">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/General/Notifications1.svg"
                  )}
                />
              </span>
              <span className="menu-text">Actividades</span>
            </NavLink>
          </li>
        ) : (
          <></>
        )}

        {/* RECORDARI */}
        { user?.RoleId !== 15  ? (
        <li 
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/recordari",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/recordari">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-check.svg")} />
            </span>
            <span className="menu-text">Recordari</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className="menu-item  menu-item-parent"
                aria-haspopup="true"
              >
                <span className="menu-link">
                  <span className="menu-text">123</span>
                </span>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/recordari/browse"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/recordari/browse">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Apartados</span>
                </NavLink>
              </li>
                  
            </ul>
          </div>
        </li> 
        ) : (
          <></>
        )}

        {/* COTIZACIONES */}
        { ((user && user.RoleId !== 11) || user.RoleId === 13) && user?.RoleId !== 15  ? (
          <li
            className={`menu-item ${getMenuItemActive("/quotation/", true)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/quotation">
              <span className="svg-icon menu-icon">
                
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Files/File-done.svg")}
                />
              </span>
              <span className="menu-text">Cotizaciones</span>
            </NavLink>
          </li>
        ) : (
          <></>
        )}

        {/* CONTRATOS */}
        { ((user && user.RoleId !== 10) || user.RoleId !== 12 || user.RoleId === 13) && user?.RoleId !== 15  ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/contract",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/contract">
              <span className="svg-icon menu-icon">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")}
                />
              </span>
              <span className="menu-text">Contratos</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Contratos</span>
                  </span>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/contract/browse"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/contract/browse">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Contratos</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/contract/upgrade"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/contract/upgrade">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Upgrade</span>
                  </NavLink>
                </li>
                
                {/* {(user.RoleId === 1 || user.RoleId === 13 ?
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/contract/signatureup"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/contract/signatureup">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Carga de Firma</span>
                  </NavLink>
                </li> :
                <></>
                )} */}

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/contract/beneficiaries"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/contract/beneficiaries">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Beneficiarios</span>
                  </NavLink>
                </li>

              </ul>
            </div>
          </li>
        ) : (
          <></>
        )}

        {/* CATALOGOS */}
        { ((user && (user.RoleId === 1 || user?.RoleId === 12 || user?.RoleId === 13)) && user?.RoleId !== 15  ? 
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/catalog",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/catalog">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">Catálogos</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Catálogos</span>
                  </span>
                </li>

                { (user?.RoleId !== 12 ?
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/catalog/activity-types"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/catalog/activity-types">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Tipos de actividad</span>
                  </NavLink>
                </li> : <></>) }

                { (user?.RoleId !== 12 ? 
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/catalog/activity-subjects"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/catalog/activity-subjects"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Asuntos</span>
                  </NavLink>
                  </li> : <></>) }

                { (user?.RoleId !== 12 ? 
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/catalog/tipify-tracings"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/catalog/tipify-tracings">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Tipificaciones</span>
                  </NavLink>
                  </li> : <></>) }

                { (user?.RoleId !== 12 ? 
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/catalog/email-templates"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/catalog/email-templates">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      Plantillas de correo eléctronico
                    </span>
                  </NavLink>
                  </li> : <></>) }

                { (user?.RoleId !== 12 ? 
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/catalog/discount-certificates"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/catalog/discount-certificates"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      Certificados de descuentos
                    </span>
                  </NavLink>
                </li> : <></>) }

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/catalog/budget-mediadigital"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/catalog/budget-mediadigital"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      Presupuesto Medios Digitales
                    </span>
                  </NavLink>
                </li>


                { (user?.RoleId === 1 ? 
                <li
                  className={`menu-item ${getMenuItemActive( "/catalog/budget-salesbyteam" )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/catalog/budget-salesbyteam"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      Presupuesto Ventas por Equipo
                    </span>
                  </NavLink>
                </li> : <></>) }

              </ul>
            </div>
          </li>
        :
          <></>
        ) }

        {/* GUARDIAS */}
        { (user?.RoleId === 1 || user?.RoleId === 13) && user?.RoleId !== 15 ? (
          <li 
            className={`menu-item menu-item-submenu ${ getMenuItemActive( "/guard", true ) }`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
              <NavLink className="menu-link menu-toggle" to="/guard">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Shield-protected.svg")} />
                  </span>
                  <span className="menu-text">Guardias</span>
                  <i className="menu-arrow" />
              </NavLink>

              <div className="menu-submenu">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                      <li
                          className={`menu-item ${getMenuItemActive( "/guard/add" )}`}
                          aria-haspopup="true"
                      >
                          <NavLink className="menu-link" to="/guard/add">
                              <i className="menu-bullet menu-bullet-dot">
                                  <span />
                              </i>
                              <span className="menu-text">Guardias Nocturnas</span>
                          </NavLink>
                      </li>

                      <li
                          className={`menu-item ${getMenuItemActive( "/guard/assigned" )}`}
                          aria-haspopup="true"
                      >
                          <NavLink className="menu-link" to="/guard/assigned">
                              <i className="menu-bullet menu-bullet-dot">
                                  <span />
                              </i>
                              <span className="menu-text">Guardias Asignadas</span>
                          </NavLink>
                      </li> 
                  </ul>
              </div>
          </li>

        ) : (
              <></>
        )}

        {/* USUARIOS */}
        {user?.RoleId === 1 || user?.RoleId === 15 ? (

        <li 
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/user",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/user">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Usuarios</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
              {/* <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Lista de usuarios ???</span>
                  </span>
                </li> */}

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/user/browse"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/user/browse">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Lista de usuarios</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/user/locations"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/user/locations"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      Ubicación de usuarios
                    </span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/user/invoicing"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/user/invoicing">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Facturación de usuarios</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>

         /*  <li
            className={`menu-item ${getMenuItemActive("/user", true)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/user">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Usuarios</span>
            </NavLink>
          </li> */
        ) : (
          <></>
        )}

      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
